<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="connectionPage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="upType">
          <el-select v-model="searchModel.upType" clearable placeholder="申请方式">
            <el-option v-for="(label, value) in stateMap" :key="value" :label="label" :value="value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="webFarmerSearch">
          <el-input v-model="searchModel.webFarmerSearch" placeholder="农户姓名 手机"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webUserSearch">
          <el-input v-model="searchModel.webUserSearch" placeholder="经办人姓名 手机"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleExport" size="mini" type="primary">批量导出</el-button>
      </template>
    </page-table>

    <el-dialog title="编辑" :visible.sync="page_dialog_visible" append-to-body
               :close-on-click-modal="false" width="50%" :close-on-press-escape="false">
      <el-form :model="pageForm" :rules="pageRules" label-width="130px" ref="pageForm"
               v-loading="page_form_loading">
        <el-form-item label="所在地址" prop="areas">
          <el-cascader ref="areaCascaderRef" v-model="pageForm.areas" :options="pca_options"
                       @change="areaChange"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
          <div>当前选择:{{pageForm.province}}{{pageForm.city}}{{pageForm.area}}</div>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input placeholder="账号" v-model="pageForm.address"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目类型" prop="projectType">
              <el-input placeholder="姓名" v-model="pageForm.projectType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="装机容量" prop="capacity">
              <el-input placeholder="装机容量" v-model="pageForm.capacity">
                <template slot="append">单位（瓦）</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="消纳方式" prop="consumeType">
              <el-select v-model="pageForm.consumeType" placeholder="消纳方式" style="width: 100%;">
                <el-option label="全额上网" value='ALL'/>
                <el-option label="自发自用" value='SELF'/>
                <el-option label="余电上网" value='SURPLUS'/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否本人办理" prop="oneself">
              <el-checkbox v-model="pageForm.oneself"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="户主姓名" prop="name">
              <el-input placeholder="户主姓名" v-model="pageForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户主手机" prop="phone">
              <el-input placeholder="户主手机" v-model="pageForm.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="身份证件" prop="idNoImg">
          <el-upload action="-" accept="image/*"
                     list-type="picture-card"
                     :http-request="(source) => handleUpload(source,'idNoImgs')"
                     :file-list="idNoImgs"
                     :on-remove="(file) => {handleRemove(file,'idNoImgs')}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="证件号码" prop="idNo"
                      :rules="[{required: true, message: '请输入身份证号', trigger: 'blur'},]">
          <el-input placeholder="身份证号" v-model="pageForm.idNo"></el-input>
        </el-form-item>
        <el-form-item label="产权证明" prop="propertyImg">
          <el-upload action="-" accept="image/*"
                     list-type="picture-card"
                     :http-request="(source) => handleUpload(source,'propertyImgs')"
                     :file-list="propertyImgs"
                     :on-remove="(file) => {handleRemove(file,'propertyImgs')}">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开户行" prop="bankName">
              <el-input placeholder="开户行" v-model="pageForm.bankName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账号" prop="bankNo">
              <el-input placeholder="银行账号" v-model="pageForm.bankNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="逆变器品牌" prop="inverterBrand">
              <el-input placeholder="逆变器品牌" v-model="pageForm.inverterBrand"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="逆变器型号" prop="inverterModel">
              <el-input placeholder="逆变器型号" v-model="pageForm.inverterModel"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="逆变器序列号" prop="inverterSerialNumber">
              <el-input placeholder="逆变器序列号" v-model="pageForm.inverterSerialNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="提交渠道" prop="consumeType">
              <el-select v-model="pageForm.consumeType" placeholder="消纳方式" disabled style="width: 100%;">
                <el-option label="线下营业厅" value='ALL'/>
                <el-option label="光e宝APP" value='SELF'/>
                <el-option label="网上国网APP" value='SURPLUS'/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input placeholder="备注" v-model="pageForm.remarks"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import pcaCode from "@/utils/pca-code"
import {connectionPage, connectionExport, uploadFile, saveConnection} from "@/api/common"
import downloadBlob from "@/function/downloadBlob"
// import helper from "@/utils/helper"
import {PIC_URL} from "@/utils/config"

const consumeTypeMap = {
  ALL: '全额上网',
  SELF: '自发自用',
  SURPLUS: '余电上网',
}

const upTypeMap = {
  DOWN: '线下营业厅',
  EAPP: '光e宝app',
  GWAPP: '网上国网app',
}

export default {
  name: "Connections",
  data() {
    return {
      searchModel: {
        upType: '',
        webFarmerSearch: '',
        webUserSearch: '',
      },
      pca_options: pcaCode,
      stateMap: upTypeMap,
      connectionPage: connectionPage,
      fields: [
        {label: '工单编号', key: 'id', width: 180},
        {label: '农户姓名', key: 'name',width:100},
        {
          label: '证件号码', width: 180, key: 'name', render({idNo}, h) {
            return h('div', idNo || '')
          }
        },
        {label: '农户电话', key: 'phone', width: 120,},
        {
          label: "农户地址", width: 160,
          render({province, city, area, address}, h) {
            province = province ? province : ''
            city = city ? city : ''
            area = area ? area : ''
            address = address ? address : ''
            return h('div', province + city + area + address)
          }
        },
        {label: "项目类型", key: 'projectType', width: 120},
        {
          label: "装机容量",
          width: 100,
          render({capacity}, h) {
            return h('div', capacity ? capacity + 'kw' : '')
          }
        },
        {
          label: "消纳方式", width: 90,
          render({consumeType}, h) {
            return h('div', consumeTypeMap[consumeType])
          }
        },
        {label: '结算账号', key: 'bankNo', width: 180},
        {label: '开户行', key: 'bankName', width: 120},
        {
          label: "申请方式",
          width: 100,
          render({upType}, h) {
            return h('div', upTypeMap[upType])
          }
        },
        {
          label: "经办人", width: 90,
          render({user}, h) {
            return h('div', user ? user.name : '')
          }
        },
        {
          label: "手机号",
          width: 120,
          render({user}, h) {
            return h('div', user ? user.phone : '')
          }
        },
        {label: '申请时间', key: 'time', width: 180, align: 'center'},
        {label: '备注', key: 'remarks', align: 'center'},
      ],
      actions: [
        {action: 'edit', label: '编辑', type: 'primary',},
      ],

      //
      page_dialog_visible: false,
      page_form_loading: false,
      idNoImgs: [], // 身份证图片
      propertyImgs: [], // 产权证明
      pageForm: {
        workOrderId: null,
        province: '',
        city: '',
        area: '',
        address: '',
        projectType: '',// 项目类型
        capacity: '', //装机容量
        consumeType: '', 	//消纳方式,可用值:ALL,SELF,SURPLUS
        oneself: true, // 是否本人办理
        name: '',// 户主姓名
        idNo: '', // 户主身份证号
        idNoImg: '', // 户主身份证图片
        propertyImg: '', // 产权证件
        phone: '', // 户主手机号
        bankName: '',// 开户行
        bankNo: '',// 开户行
        upType: '', // 提交渠道,可用值:DOWN,EAPP,GWAPP
        userId: '',
        version: '',
        areas: [],
        inverterBrand: '',
        inverterModel: '',
        inverterSerialNumber: '',
      },
      pageRules: {
        province: [
          {required: true, message: '请选择地区', trigger: 'blur'},
        ],
        city: [
          {required: true, message: '请选择地区', trigger: 'blur'},
        ],
        area: [
          {required: true, message: '请选择地区', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
        projectType: [
          {required: true, message: '请输入项目类型', trigger: 'blur'},
        ],
        capacity: [
          {required: true, message: '请输入装机容量', trigger: 'blur'},
        ],
        consumeType: [
          {required: true, message: '请选择消纳方式', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入户主姓名', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证件', trigger: 'blur'},
        ],
        idNoImg: [
          {required: true, message: '请上传身份证件', trigger: 'blur'},
        ],
        propertyImg: [
          {required: true, message: '请上传产权证件', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入户主手机号', trigger: 'blur'},
        ],
        bankName: [
          {required: true, message: '请输入开户行', trigger: 'blur'},
        ],
        bankNo: [
          {required: true, message: '请输入银行账号', trigger: 'blur'},
        ],
        upType: [
          {required: true, message: '请选择提交渠道', trigger: 'blur'},
        ],
        // 逆变器品牌
        inverterBrand: [
          {required: true, message: '请填写逆变器品牌'},
        ],
        // 逆变器型号
        inverterModel: [
          {required: true, message: '请填写逆变器型号'},
        ],
        // 逆变器序列号
        inverterSerialNumber: [
          {required: true, message: '请填写逆变器序列号'},
        ],
      },
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    editAction(item) {
      const { inverterBrand, inverterModel, inverterSerialNumber }  = item
      this.pageForm = {
        id: item.id,
        workOrderId: item.workOrderId,
        province: item.province,
        city: item.city,
        area: item.area,
        address: item.address,
        projectType: item.projectType,
        capacity: item.capacity,
        consumeType: item.consumeType, 	//消纳方式,可用值:ALL,SELF,SURPLUS
        oneself: item.oneself, // 是否本人办理
        name: item.name,// 户主姓名
        idNo: item.idNo, // 户主身份证号
        idNoImg: item.idNoImg, // 户主身份证图片
        propertyImg: item.propertyImg, // 产权证件
        phone: item.phone, // 户主手机号
        bankName: item.bankName,// 开户行
        bankNo: item.bankNo,// 开户行
        upType: item.upType, // 提交渠道,可用值:DOWN,EAPP,GWAPP
        userId: item.userId,
        areas: [item.province, item.city, item.area],
        version: item.version,
        inverterBrand, inverterModel, inverterSerialNumber
      }

      let imgs = this.pageForm.idNoImg.length > 0 ? this.pageForm.idNoImg.split(',') : []
      this.idNoImgs = this.getImgList(imgs)
      imgs = this.pageForm.propertyImg.length > 0 ? this.pageForm.propertyImg.split(',') : []
      this.propertyImgs =this.getImgList(imgs)
      this.page_dialog_visible = true
    },
    handleUpload(source, key) {
      let that = this
      uploadFile(source.file).then(res => {
        that[key].push({url:PIC_URL+ res})
      })
    },
    handleRemove(file, key) {
      let that = this
      for (let i = 0; i < that[key].length; i++) {
        if (file.url == that[key][i].url) {
          that[key].splice(i, 1)
        }
      }
    },
    getImgList(arr = []) {
      let temp = []
      for (let pic of arr) {
        temp.push({
          url: PIC_URL + pic
        })
      }
      return temp
    },
    handleExport() {
      let that = this, ids = []
      let selected = that.$refs.table.selection
      for (let item of selected) {
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      connectionExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    areaChange(val) {
      this.pageForm.province = val[0]
      this.pageForm.city = val[1]
      this.pageForm.area = val[2]
      this.pageForm.areas = val
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        let temp1 = [],temp2= []
        for(let item of that.idNoImgs){
          temp1.push(item.url.replace(PIC_URL, ''))
        }
        for(let item of that.propertyImgs){
          temp2.push(item.url.replace(PIC_URL, ''))
        }
        that.pageForm.idNoImg = temp1.join(',')
        that.pageForm.propertyImg = temp2.join(',')
        that.page_form_loading = true
        saveConnection(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.multi-upload {
  .el-icon-plus {
    width: 148px;
    height: 148px;
    line-height: 148px;
  }

  .el-uploader-image {
    width: 148px;
    height: 148px;
    line-height: 148px;
  }
}
</style>

